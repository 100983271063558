import { GiBackwardTime, GiPolarStar } from "react-icons/gi";
import { MdThumbUp } from "react-icons/md";
import { IoIosFlower } from "react-icons/io";



// export const lawData = [
//   {
//       "src": require('../assets/images/Political-Law.png'),
//       "title": "Real State Law",
//       "paragraph": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed "
//   },
//   {
//       "src": require('../assets/images/Business-Law.png'),
//       "title": "Political Law",
//       "paragraph": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed"
//   },
//   {
//       "src": require('../assets/images/Divorse-Law.png'),
//       "title": "Divorce Law",
//       "paragraph": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed"
//   },
//   {
//       "src": require('../assets/images/Political-Law.png'),
//       "title": "Business Law",
//       "paragraph": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed"
//   },
//   {
//       "src": require('../assets/images/Divorse-Law.png'),
//       "title": "Industrial Law",
//       "paragraph": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed"
//   },
//   {
//     "src": require('../assets/images/Political-Law.png'),
//     "title": "Business Law",
//     "paragraph": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed"
// },
// // {
// //   "src": require('../assets/images/Political-Law.png'),
// //   "title": "Business Law",
// //   "paragraph": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed"
// // },
// // {
// //   "src": require('../assets/images/Political-Law.png'),
// //   "title": "Business Law",
// //   "paragraph": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed"
// // },
// ];


// export const exploreCardData =[
//   {
//     // "src": require("../assets/images/Quality-icon.png"),
//     "component" :<GiPolarStar />    ,
//     "title": "Quaity",
//     "paragraph": "We prioritize delivering high-quality services to our clients, ensuring their satisfaction and Success"
//   },
//   {
//     //  "src": require("../assets/images/Quality-icon.png"),
//     "component" :<GiBackwardTime />    ,
//       "title": "Reliability",
//       "paragraph": "Our commitment to reliability means we consistently deliver our services on time and as promised"
//     },

//     {
//       // "src": require("../assets/images/Quality-icon.png"),
//     "component" :<IoIosFlower />    ,
//       "title": "Versatility",
//       "paragraph": "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed d"
//     },

//     {
//       // "src": require("../assets/images/Quality-icon.png"),
//     "component" :<MdThumbUp />    ,
//       "title": "Customer  Satisfaction",
//       "paragraph": "We strive to understand and exceed our clients' expectations, aiming for their overall satisfaction."
//     }

// ]

export const blogData = [
  {
    "src": require("../assets/images/team-1.png"),
    "name": "  Tarique Hussain",
    "designation": " legal consultant"
  },
  {
    "src": require("../assets/images/team-2.png"),
    "name": "  Tarique Hussain",
    "designation": " legal consultant"
  },
  {
    "src": require("../assets/images/team-3.png"),
    "name": "  Tarique Hussain",
    "designation": " legal consultant"
  },
  {
    "src": require("../assets/images/team-4.png"),
    "name": "  Tarique Hussain",
    "designation": " legal consultant"
  }

]

export const Attorney = [
  {
    id: 1,
    src: require("../assets/images/team-1.png"),
    name: "WALEED AHMED SIDDIQUI",
    description: "CEO",
    // profileImage: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=387&q=80",
  },
  {
    id: 2,
    src: require("../assets/images/team-2.png"),
    name: "MUZAFFAR AHMED JUMANI ",
    description: "COO",
    // profileImage: "https://images.unsplash.com/photo-1459499362902-55a20553e082?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=869&q=80",
  },
  {
    id: 3,
    src: require("../assets/images/team-3.png"),
    name: "FAEQ ALI ",
    description: "GM(HR)",
    // profileImage: "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=387&q=80",
  },
  {
    id: 6,
    src: require("../assets/images/team-4.png"),
    name: "SANJA KUMAR PINJANI",
    description: "CONSULTANT",
    // profileImage: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
  },
  {
    id: 7,
    src: require("../assets/images/team-1.png"),
    name: "TARIQUE HUSSAIN",
    description: "LEGAL CONSULTANT",
    // profileImage: "https://plus.unsplash.com/premium_photo-1667509266816-421f39ae5f3a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=872&q=80",
  },
  {
    id: 8,
    src: require("../assets/images/team-4.png"),
    name: "MOHSIN JAVED",
    description: "LEGAL CONSULTANT",
    // profileImage: "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=388&q=80",
  },

];


export const generalAskData = [
  {
    question: "How much cost for this service?",
    answer: "The cost varies depending on the specific requirements and scope of the service. Please contact us for a customized quote.",
  },
  {
    question: "Is there any hidden cost?",
    answer: "No, there are no hidden costs. All fees and charges will be discussed upfront and clearly outlined in your service agreement.",
  },
  {
    question: "Why choose this service?",
    answer: "Our service offers industry-leading expertise, personalized attention, and a commitment to helping you achieve your goals efficiently.",
  },
];



// export const Slider2Data= [
//   {
//     "src":require('../assets/images/logo01.png')
//   },
//   {
//     "src":require('../assets/images/logo02.png')
//   },
//   {
//     "src":require('../assets/images/logo03.png')
//   },
//   {
//     "src":require('../assets/images/logo04.png')
//   },
//   {
//     "src":require('../assets/images/logo05.png')
//   }
// ]